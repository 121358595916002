import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TriggerFocusDirective } from './trigger-focus.directive';



@NgModule({
  declarations: [
    TriggerFocusDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [TriggerFocusDirective]
})
export class TriggerFocusModule { }
